<template>
  <div>
    <common-header></common-header>
    <div class="form-box warp">
      <div>
        <div class="title">反馈内容</div>
        <textarea placeholder="请输入内容" v-model="da.content"></textarea>
      </div>

      <!-- 上传图片 start -->
      <div class="title">上传图片</div>
      <div class="up-img-box" v-if="da.img == ''">
        <img class="icon" :src="require('@/assets/images/img/img-up.png')" alt="">
        <input type="file" class="picture" accept="image/*" @change="choicePicture($event)">
      </div>
      <div class="img-box" v-else :style="`background-image:url('${requestImgUrl(da.img)}')`">
        <img :src="requestImgUrl(da.img)" :preview="1" alt="">
        <div class="delete-img" @click="da.img = ''"></div>
      </div>
      <!-- 上传图片 end -->
    </div>

    <div class="sub-btn jb-r-l" @click="Submit">提交</div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, uploadFile } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    return {
      da:{
        content: "",
        img: "",
      },
    }
  },
  mounted() {

  },
  methods: {
    choicePicture(event) {
      this.$Indicator.open({
        text: '上传中...',
        spinnerType: 'snake',
      });
      const files = event.target.files[0];
      uploadFile({
        file: files,
        cb: v => {
          let { da } = this;
          da.img = v.fileUri;
          this.da = da; 
          this.$Indicator.close();
          this.$previewRefresh();
        },
      })
    },
    // 提交反馈
    Submit() {
      let { da } = this;
      if (da.content == "" || da.content.length < 15) {
        this.$toast("请输入15字以上的提交反馈");
      } else {
        this.PostFeedback(da).then((res)=> {
          this.$toast("提交成功");
          this.da = {
            content: "",
            img: "",
          }
        })
      }
    },
    ...mapActions('account', [
      'PostFeedback',
    ])
  },
}
</script>
<style lang='less' scoped>
.delete-img{
  width: .4rem;
  height: .4rem;
  border-radius: 50%;
  background: #ff7141;
  position: absolute;
  top: -.2rem;
  right: -.2rem;
  background-image: url('../../assets/images/img/delete.png');
  background-size: 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.img-box{
  position: relative;
  width: 1.9rem;
  height: 1.9rem;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  img{
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
.up-img-box{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .picture{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .icon{
    width: .41rem;
    height: .36rem;
  }
}
.up-img-box{
  width: 1.05rem;
  height: 1.05rem;
  background: #f7f7f7;
  border-radius: .05rem;
}
.title{
  font-size: .28rem;
  color: #262626;
  margin-bottom: .27rem;
}
.sub-btn{
  width: 4.8rem;
  height: .85rem;
  line-height: .85rem;
  text-align: center;
  border-radius: .42rem;
  font-size: .34rem;
  color: #ffffff;
  margin: 1.3rem auto 0;
}
.form-box{
  padding-top: .2rem;
  .item{
    display: flex;
    align-items: center;
    &.ft{
      margin-top: .3rem;
      align-items: flex-start;
    }
  }
  input{
    flex: 1;
    line-height: .9rem;
    border-bottom: 1px solid #f7f7f7;
  }
  textarea{
    width: 100%;
    background: #f7f7f7;
    border-radius: .05rem;
    height: 2.3rem;
    padding: .2rem;
    margin-bottom: .45rem;
    font-size: 0.3rem;
  }
  label{
    width: 2.2rem;
    display: block;
  }
}
</style>